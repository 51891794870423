<template>
  <Modal v-model="modifyStockQuantityModal" :title="$t('key1005498')" width="800" :transfer="false"
      :mask-closable="false" @on-visible-change="modifyStockQuantityChange">
    <div class="table_box">
      <Table
          highlight-row
          border
          max-height="500"
          :loading="tableLoading"
          :columns="tableColumns"
          :data="tableData">
      </Table>
    </div>
    <template #footer>
      <div style="text-align: right;">
        <Button @click="modifyStockQuantityModal = false">{{ $t('key1000097') }}</Button>
        <Button type="primary" @click="modifyStockQuantityBtn" :disabled="tableData.length <=0">{{ $t('key1000096') }}</Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from "@/api";
import {commonCopyContent, setConfigCharacters} from "@/utils/common";

export default {
  name: "modifyStockQuantityModal",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      modifyStockQuantityModal: false,
      tableColumns: [
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000205'),
          key: 'skuInfo',
          align: 'left',
          minWidth: 350,
          render(h, params) {
            if (params.row._productInfo) {
              let supplierSku = params.row._productInfo.sku || ''; // 供应商sku
              let ymsSku = params.row._productInfo.ymsSku || ''; // 云卖sku
              let productGoodsSpecifications = params.row._productInfo.productGoodsSpecifications || [];
              let spec = productGoodsSpecifications.map(i => i.name + '：' + i.value).join('、');
              let skuImgPath = params.row._productInfo.primaryImage;
              let spuImgPath = params.row._productInfo._spuInfo.primaryImage;
              let imgPath = skuImgPath ? skuImgPath : spuImgPath;
              let list = [
                v.tableImg(h, params, null, imgPath),
                h('div', {
                  class: 'text-align-left pl10'
                }, [
                  h('Alink', {
                    props: {
                      linkName: params.row._productInfo._spuInfo.name,
                      textAlign: 'left',
                      className: 'mb5',
                      AlinkShow: true
                    },
                    on: {
                      AlinkBtn: () => {
                        const {href} = v.$router.resolve({
                          path: '/productDetails',
                          query: {
                            type: 'look',
                            productId: params.row.productGoodsId
                          }
                        });
                        window.open(href, '_blank');
                      }
                    }
                  }),
                  h('p', {class: 'flex align-items-center mb5'}, [
                    h('span', {style: {color: '#000'}}, alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003253') + supplierSku),
                    supplierSku ? h('Icon', {
                      props: {
                        type: 'ios-copy-outline',
                        size: 18,
                        color: '#01a6ee'
                      },
                      style: {
                        marginLeft: '10px',
                        cursor: 'pointer'
                      },
                      attrs: {
                        title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000000')
                      },
                      on: {
                        click: () => {
                          commonCopyContent(supplierSku, alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000004'));
                        }
                      }
                    }) : ''
                  ]),
                  h('p', {class: 'flex align-items-center mb5'}, [
                    h('span', {style: {color: '#000'}}, `${setConfigCharacters}SKU：` + ymsSku),
                    ymsSku ? h('Icon', {
                      props: {
                        type: 'ios-copy-outline',
                        size: 18,
                        color: '#01a6ee'
                      },
                      style: {
                        marginLeft: '10px',
                        cursor: 'pointer'
                      },
                      attrs: {
                        title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000000')
                      },
                      on: {
                        click: () => {
                          commonCopyContent(ymsSku, alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000004'));
                        }
                      }
                    }) : ''
                  ]),
                  h('div', {class: 'flex align-items-center'}, [
                    h('p', alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000206')),
                    h('p', {
                      style: {
                        flex: 1
                      },
                      class: 'color-green'
                    }, spec),
                  ])
                ])
              ];
              return h('div', {
                class: 'flex ptb10',
              }, list)
            }
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003472'),
          minWidth: 100,
          key: 'quantity',
          align: 'left',
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005499'),
          minWidth: 190,
          key: 'updateQuantity',
          align: 'left',
          render: (h, params) => {
            return h('div', [
              h('InputNumber', {
                props: {
                  min: 0,
                  max: 99999999,
                  value: params.row.updateQuantity,
                  formatter: (value) => parseInt(value),
                  placeholder: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005500')
                },
                style: {
                  width: '160px'
                },
                on: {
                  input: value => {
                    v.$set(v.tableData[params.index], 'updateQuantity', value);
                  }
                }
              })
            ]);
          }
        },
      ],
      tableData: [],
      packageId: null,
      packageCode: null,
    }
  },
  methods: {
    // 初始化数据
    getModifyStockData(packageCode, packageId) {
      this.packageCode = packageCode;
      this.packageId = packageId;
      this.axios.get(api.get_packageInfo_queryAllPackageGoodsById + packageId,
        {loading: true, loadingText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001180')}).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          if (data.length > 0) {
            let productGoodsIds = [];
            data.map((item) => {
              item.updateQuantity = null;
              productGoodsIds.push(item.productGoodsId);
            });
            if (productGoodsIds.length > 0) {
              (async () => {
                this.tableData = await this.getProductInfoByIds(productGoodsIds, data,
                  false, false, false, api.post_productInfo_queryByProductGoodsIds, 'supplier');
              })();
            } else {
              this.tableData = data;
            }
          }
          this.tableData = data;
        }
      });
      this.modifyStockQuantityModal = true;
    },
    // 确定按钮
    modifyStockQuantityBtn() {
      let v = this;

      function isNullOrEmpty(value) {
        return value === undefined || value === '';
      }

      let query = {
        packageCode: v.packageCode,
        packageId: v.packageId,
        updatePackageGoods: []
      };
      if (v.tableData.length > 0) {
        let talg = v.tableData.every((item) => {
          return item.updateQuantity === null || item.updateQuantity === '' || item.updateQuantity === undefined
        });
        if (talg) {
          v.$Message.warning(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005501'));
          return false;
        } else {
          v.tableData.map((item) => {
            query.updatePackageGoods.push({
              originalQuantity: item.quantity,
              packageGoodsId: item.packageGoodsId,
              productGoodsId: item.productGoodsId,
              sku: item.sku,
              updateQuantity: item.updateQuantity
            })
          })
        }
        v.axios.put(api.put_packageInfo_upatePackageGoodsQuantity, query, {loading: true, loadingText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000212')}).then(response => {
          if (response.data.code === 0) {
            v.$Message.success(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005502'));
            v.modifyStockQuantityModal = false;
            v.$emit('updateData', true);
          }
        });
      }
    },
    // 监听弹窗
    modifyStockQuantityChange(value) {
      if (!value) {
        this.tableData = [];
        this.packageId = null;
        this.packageCode = null;
      }
    }
  }
}
</script>

<style scoped>

</style>